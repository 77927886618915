import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  Col,
  Label,
} from "reactstrap";
import {
  AvForm, AvField,
  AvRadio,
  AvRadioGroup
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { editBonus, fetchAccountTypes } from "store/actions";
import { validateFile } from "pages/Transactions/Crypto/AddDepositForm";

const ClientType = ["LIVE", "IB", "INVESTOR", "SP"];
const PROCESS = ["Deposit", "Welcome"];
const BONUSTYPE = ["DEPOSIT", "CREDIT"];

function BonusEdit({ open, onClose, selectBonus, t }) {
  
  const dispatch = useDispatch(); 
  const { updating, error } = useSelector((state) => state.bonusReducer);
  
  const [bonusType, setBonusType] = useState([]);
  const [file, setFile] = useState({});
  const [process, setProcess] = useState();
  const [accountGroup, setAccountGroup] = useState([]);

  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);

  useEffect(() => {
    (!updating && !error && open) && onClose();
  }, [updating]);
  useEffect(()=>{loadAccountTypes}, []);

  const loadAccountTypes = () => {
    dispatch(fetchAccountTypes());
  };

  const handleSubmit = (e, v) => {
    e.preventDefault();
    const id = selectBonus?._id;
  

    const formData = new FormData();
    formData.append("bonusName", v.bonusName);
    formData.append("process", v.process);
    formData.append("clientType", v.clientType);
    formData.append("banner", file);
    formData.append("accountGroup", accountGroup.title);
    formData.append("bonusType", v.bonusType);
    formData.append("amount", parseFloat(v.amount ?? 0));
    formData.append("maxAmount", parseFloat(v.maxAmount ?? 0));
    formData.append("duration", v.duration);
    formData.append("accountType", accountGroup._id || selectBonus.accountType); 
    formData.append("desc", v.desc);    
    formData.append("isPercentage", v.isPercentage);       
             
    dispatch(editBonus({
      id,
      formData,
    }));
    
    
  };


  return (
    <>
      <Modal isOpen={open} toggle={onClose} centered={true}>
        <ModalHeader toggle={onClose} tag="h4">
          Edit Bonus
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4'
            onValidSubmit={(e, v) => { handleSubmit(e, v) }}
          >
            <AvField
              name="bonusName"
              label="Bonus Name"
              placeholder="Bonus Name "
              type="text"
              value={selectBonus?.bonusName}
              validate={{
                // required: {
                //   value: true,
                //   errorMessage: "Bonus Name is required"
                // }
              }}
            />
            <AvFieldSelect
              name="process"
              label="Process"
              placeholder="Select Process"
              options={PROCESS?.map((type) => ({
                value: type,
                label: type
              }))}
              value={selectBonus?.process}
              onChange={(e) => setProcess(e)}
              validate={{
                // required: {
                //   value: true,
                //   errorMessage: "Type is required"
                // }
              }}
            />
            
            <AvFieldSelect
              name="clientType"
              label="Client Type"
              placeholder="Select Client Type"
              options={ClientType.map((type) => ({
                value: type,
                label: type
              }))}
              value={selectBonus?.clientType}

              validate={{
                // required: {
                //   value: true,
                //   errorMessage: "Client Type is required"
                // }
              }}
            />
              
            
            <div className="mb-3">
              {/* image */}
              <Col md="12">
                <Label className="form-label" htmlFor="banner">
                  {t("Banner")}
                </Label>
                <AvField
                  type="file"
                  name="banner"
                  className="form-control form-control-md"
                  errorMessage={t("Banner is required")}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  validate={{
                    // required: { value: true },
                    validate: validateFile(["jpg", "jpeg", "png"], 1000000, file, {
                      sizeValidationMessage: t("The file size is too large"),
                      extensionValidationMessage: t("The file extension is not allowed"),
                    })
                  }}
                />
              </Col>
            </div>

            <AvFieldSelect
              name="accountGroup"
              label="Account Group"
              placeholder="Select Account Group Options"
              // accountTypes?.filter((type) => type.type === "LIVE")?
              options={accountTypes?.filter((type) => type.type === "LIVE")?.map((type) => ({
                value: type.title,
                label: type.title
              }))}
              value={selectBonus?.accountGroup}
              onChange={(e) => {
                let x = accountTypes?.filter((type)=> type.title === e);
                
                setAccountGroup(x[0]);
              }}
              validate={{
                // required: {
                //   value: true,
                //   errorMessage: "Leverage options is required"
                // }
              }}
            />
            <AvFieldSelect
              name="bonusType"
              label="Bonus Type"
              placeholder="Select Bonus Type"
              options={BONUSTYPE.map((type) => ({
                value: type,
                label: type
              }))}
              value = {selectBonus?.bonusType}
              onChange={(e) => {
                setBonusType(e);
              }}
              validate={{
                // required: {
                //   value: true,
                //   errorMessage: "Bonus Type is required"
                // }
              }}
            />
            <div className="fs-3">
              
              <AvRadioGroup  inline name="isPercentage" value={`${selectBonus?.isPercentage}`} label={<span style={{ 
                fontSize: "1rem",
                fontWeight: "bold"
              }}>is percentage ?</span>} validate={{
                required: {
                  value: true,
                  errorMessage: "percentage is required"
                }
              }} >
                <AvRadio label="true" value="true" />
                <AvRadio label="false" value="false" />
              </AvRadioGroup>
            </div>

            <div className="mb-3 d-flex justify-content-between">
              <AvField
                name="amount"
                label={t("Amount")}
                placeholder={t("Enter Amount")}
                type={process === "Welcome" ? "text" : "number"}
                min={0}
                max={process === "Deposit" ? 100 : 1000000000}
                value = {selectBonus?.amount}
                onKeyPress={(e) => {
                  if (!isNaN(e.key) && e.target.value.length > 0){
                    return true;
                  }
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                validate={
                  {
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "testing error message"
                    },
                  }
                }
              />
            </div>

            {selectBonus?.process !== "Welcome" && (
              <div className="mb-3">
                <AvField
                  name="maxAmount"
                  label={t("Max Amount")}
                  placeholder={t("Enter Max Amount")}
                  type="text"
                  value = {selectBonus?.maxAmount}
                  onKeyPress={(e) => {
                    if (!isNaN(e.key) && e.target.value.length > 0){
                      return true;
                    }
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  validate={
                    {
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "testing error message"
                      },
                    }
                  }
                />
              </div>

            )

            }
            <AvField
              name="duration"
              label="Duration"
              placeholder="Duration"
              type="number"
              value = {selectBonus?.duration}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Duration is required"
                }
              }}
            />
            <AvField
              name="desc"
              label="Desc"
              placeholder="Desc "
              type="textarea"
              value={selectBonus?.desc}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Desc  is required"
                }
              }}
            />
            <div className='text-center mt-3 p-2'>
              {
                updating
                  ? <Loader />
                  : <Button disabled={updating} type="submit" color="primary" onClick={onClose}>
                    Edit
                  </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default BonusEdit;