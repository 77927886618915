import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  Col,
  Label,
} from "reactstrap";
import {
  AvForm, AvField,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadio,
  AvRadioGroup, 
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import useModal from "hooks/useModal";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { validateFile } from "pages/Transactions/Crypto/AddDepositForm";
import { addBonus } from "store/bonus/actions";
import { fetchAccountTypes } from "store/actions";

const ClientType = ["LIVE", "IB", "INVESTOR", "SP"];
const PROCESS = ["Deposit", "Welcome"];
const ACCOUNTGROUP = ["Executive MT4", "Executive MT5"];
const BONUSTYPE = ["DEPOSIT", "CREDIT"];


function AddBonus(props) {
  const dispatch = useDispatch();

  const [showModal, toggleModal] = useModal(false);
  const [accountGroup, setAccountGroup] = useState([]);
  const [bonusType, setBonusType] = useState([]);
  const [file, setFile] = useState({});
  const [process, setProcess] = useState();

  
  const create = useSelector((state) => state.Profile?.AccTypesPermissions?.create);
  const {accountTypes, submitting, error } = useSelector((state) => state.tradingAccountReducer);
  console.log(accountTypes);
  useEffect(() => {
    (!submitting && !error && showModal) && toggleModal();
  }, [submitting]);
  useEffect(()=>{loadAccountTypes}, []);

  const loadAccountTypes = () => {
    dispatch(fetchAccountTypes());
  };
  

  const handleSubmit = (e, v) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("bonusName", v.bonusName);
    formData.append("process", v.process);
    formData.append("clientType", v.clientType);
    formData.append("banner", file);
    formData.append("accountGroup", v.accountGroup.title);
    formData.append("bonusType", v.bonusType);
    formData.append("amount", parseFloat(v.amount ?? 0));
    formData.append("maxAmount", parseFloat(v.maxAmount ?? 0));
    formData.append("duration", v.duration);
    formData.append("accountType", v.accountGroup._id);
    formData.append("desc", v.desc);       
    formData.append("isPercentage", v.isPercentage);       
    
    dispatch(addBonus(formData));
  };
  
  return (
    <>
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleModal}><i className="bx bx-plus me-1"></i> Add New Bonus</Link>
      <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          Add New Bonus
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4'
            onValidSubmit={(e, v) => { handleSubmit(e, v) }}
          >
            <AvField
              name="bonusName"
              label="Bonus Name"
              placeholder="Bonus Name "
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Bonus Name is required"
                }
              }}
            />
            <AvFieldSelect
              name="process"
              label="Process"
              placeholder="Select Process"
              options={PROCESS?.map((type) => ({
                value: type,
                label: type
              }))}
              value={process}
              onChange={(e) => setProcess(e)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Type is required"
                }
              }}
            />
            {
              <AvFieldSelect
                name="clientType"
                label="Client Type"
                placeholder="Select Client Type"
                options={ClientType.map((type) => ({
                  value: type,
                  label: type
                }))}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Client Type is required"
                  }
                }}
              />
              
            }
            <div className="mb-3">
              {/* image */}
              <Col md="12">
                <Label className="form-label" htmlFor="banner">
                  {props.t("Banner")}
                </Label>
                <AvField
                  type="file"
                  name="banner"
                  className="form-control form-control-md"
                  errorMessage={props.t("Banner is required")}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  validate={{
                    // required: { value: true },
                    validate: validateFile(["jpg", "jpeg", "png"], 1000000, file, {
                      sizeValidationMessage: props.t("The file size is too large"),
                      extensionValidationMessage: props.t("The file extension is not allowed"),
                    })
                  }}
                />
              </Col>
            </div>
            {/* .filter((type) => "Executive MT4" || "Executive MT5") */}
            <AvFieldSelect
              name="accountGroup"
              label="Account Group"
              placeholder="Select Account Group Options"
              options={accountTypes?.filter((type) => type.type === "LIVE")?.map((type) => ({
                value: type,
                label: type.title
              }))}
              onChange={(e) => {
                setAccountGroup(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Leverage options is required"
                }
              }}
            />
            
            <AvFieldSelect
              name="bonusType"
              label="Bonus Type"
              placeholder="Select Bonus Type"
              options={BONUSTYPE.map((type) => ({
                value: type,
                label: type
              }))}
              onChange={(e) => {
                setBonusType(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Bonus Type is required"
                }
              }}
            />
            <div className="fs-3">
              
              <AvRadioGroup  inline name="isPercentage" label={<span style={{ 
                fontSize: "1rem",
                fontWeight: "bold"
              }}>is percentage ?</span>} validate={{
                required: {
                  value: true,
                  errorMessage: "percentage is required"
                }
              }} >
                <AvRadio label="true" value="true" />
                <AvRadio label="false" value="false" />
              </AvRadioGroup>
            </div>
            <div className="mb-3 d-flex  position-relative">
              <div className="position-relative w-100">
                <AvField
                  name="amount"
                  label={props.t("Amount")}
                  placeholder={props.t("Enter Amount")}
                  type={process === "Welcome" ? "text" : "number"}
                  min={0}
                  max={process === "Deposit" ? 100 : 1000000000}
                  onKeyPress={(e) => {
                    if (!isNaN(e.key) && e.target.value.length > 0) {
                      return true;
                    }
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  validate={{
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "testing error message"
                    },
                  }}
                />
              </div>
              
            </div>
           
            <div className="mb-3">
              <AvField
                name="maxAmount"
                label={props.t("Max Amount")}
                placeholder={props.t("Enter Max Amount")}
                type="text"
                onKeyPress={(e) => {
                  if (!isNaN(e.key) && e.target.value.length > 0){
                    return true;
                  }
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                validate={
                  {
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "testing error message"
                    },
                  }
                }
              />
            </div>

            
            <AvField
              name="duration"
              label="Duration"
              placeholder="Duration"
              type="number"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Duration is required"
                }
              }}
            />
            <AvField
              name="desc"
              label="Desc"
              placeholder="Desc "
              type="textarea"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Desc  is required"
                }
              }}
            />

            <div className='text-center mt-3 p-2'>
              {
                submitting
                  ? <Loader />
                  : <Button disabled={props.addLoading} onClick={toggleModal} type="submit" color="primary">
                    Add
                  </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddBonus;